import React from "react";

import './Card.css'


const Card = props=>{
    return(
        <div className="card text-center shadow" style={{height:'100%'}}>
            <div className="overflow">
                <img src = {props.srcPath} alt="" className="card-img-top"  />
            </div> 
            <h2 className="card-title">{props.label}</h2>
            <div className="card-body text-dark"> 
                <h2 className="card-title">{props.title}</h2>
                  {props.text===""?null:
                <p className="card-text text-secondary">
                   {props.text}
                   </p>}
             {props.more===""?null:   <a href={props.path} className="btn btn-outline-success">Click to view more</a>}

            </div>
        </div>
    )
}

export default Card