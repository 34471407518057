import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/DFO.png"

export default function Finance(){
    
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>FINANCE UNIT</h1>
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR. KENNETH A. KANSIGI</h3>
            <p style={{textAlign:"center"}}><b>DISTRICT FINANCE OFFICER</b></p>
            </div>
            </div>
        <Footer/>
        </>
    )
}
