import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/A1.jpg"

export default function Education(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069", marginRight:"30px", marginLeft:"30px", marginBottom:"10px"}}>EDUCATION</h1>
       
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MRS.PHYLIS BOATENG</h3>
            <p style={{textAlign:"center"}}><b>DISTRICT EDUCATION DIRECTOR</b></p>
            </div>
            <div style={{marginLeft:"50px", marginRight:"50px", fontFamily:"sans-serif", fontSize:"20px"}}>
            <p>The Education, Youth and Sport Unit primarily assists in the formulation and implementation of policies on education in the Assembly within the framework of national policies and guidelines.</p>
            <p>It also has an advisory role in the Assembly on matters relating to pre-school, primary, Junior High Schools in the Metropolis and other matters that may be referred to it by the Assembly.</p>
            <p>The department also encourages and reports on implementation of policies and matters relating to basic education in accordance with report format provided by the minister.</p>
            </div>
            </div>
        <Footer/>
        </>
    )
}