import React from "react";
import Navbar from "../Resources/Navbar";
import Banner from "../Resources/Banner";
import Main from "./Main";
import Footer from "../Resources/Footer";
import Services from "../Pages/Services";
import Assembly from "../Pages/Assembly";
import News from "../Pages/News";

const Home=()=>{ 
    
    return( 
        <div  style={{display:"flex", flexDirection:"column"}}>
<Banner/>    
<Navbar/>
<Main/>
<News hide="true" filter="1"/>
<Services hide="true"/>
<Assembly hide="true"/>
<Footer/>
        </div>
    )
}
export default Home
