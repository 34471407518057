import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/building.jpg"
import ServiceItem from "../Resources/ServiceItem"

export default function BuildingPermit(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <ServiceItem heading="BUILDING PERMIT" src={pic}/>
        <div>
        <h2 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>BUILDING PERMIT PROCESS</h2>
        <h3 style={{fontWeight:"bold"}}>Acquisition of Building/Development Permit</h3>
            <p>Forms for new development, re-development and extensions to existing structures, change of use, Redesigning etc. are available for potential developers at the Assembly’s Finance Department and Physical Planning Department.</p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>1.	Who can apply?</h3>
            <p>Any person/organization who intends to build on a plot of land for which one has title</p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>2.	How to Apply</h3>    
            <ul typeof="I">
                <li>
                	Purchase the Building Permit Application Form (BPA) and PPD Form one (1) from the development control unit of Physical Planning Department.
                </li>
                <li>
                	Complete in full, both the building permit application form and the PPD Form 1 and submit the completed forms to the officer in charge at the development control office of the Physical Planning Department. 
                </li>
            </ul>
            <h3 style={{fontWeight:"bold"}}>NB:  Where you have difficulty in filling the forms, contact the Physical Planning Department offices for advice and assistance</h3> 
            <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>3.	Requirements</h3> 
            <p>The prospective developer must have the following:</p>
                <ol typeof="i">
                    <li>Title Clearance form duly signed by appropriate authority (certification of ownership)</li>
                    <li>Four (4) sets of site plans, (scale 1:2,500)</li>
                    <li>Four (4) sets of building fence and block plans (scale not less than 1:20 or 1:40or metric equivalent 1:1000 & 1:2000).</li>
                    <li>Building Permit Application Form & Physical Planning Department (PPD) Form 1(one).</li>
                    <li>Ensure that the underlisted professionals sign the various plans to be attached to the building permit application.</li>
                    <li>Professional Physical Planner to sign a Block Plan</li>
                    <li>Architectural Licensed draughtsman for Architectural plans.</li>
                    <li>Civil/Structural Engineer for two-storey & above for structural drawings.</li>
                    <ol>
                        <li>Two (2) self-addressed envelopes </li>
                    </ol>
                </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>APPLICANTS REQUIRING PERMISSION IN PRINCIPLE (AIP)</h3>
                <ol type="i">
                    <li>Three (3) sets of sketch drawings</li>
                    <li>Three (3) copies of brief outline of project covering the location, design, activities and operational characteristics.</li>
                    <li>Evidence of Neighborhood consultation and comments</li>
                </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>APPLICATIONS SEEKING CHANGE OF USE OF AN EXISTING PERMIT</h3>
                <ol type="i">
                    <li>Previous permit on existing building</li>
                    <li>Proposed amendments to drawings if relevant</li>
                    <li>Justification report</li>
                    <li>Evidence of Neighborhood consultation and comments for the new use of premises</li>
                </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>VALIDATION OF DEVELOPMENT AND BUILDING PERMIT</h3>
                    <ol type="i">
                        <li>Development and Building permits are valid for five years.</li>
                        <li>Applicants who are unable to complete developments within permit validity period are required to seek permit for Extension of Time.</li>
                    </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>APPLICATION FOR EXTENSION TO EXISTING BUILDING SHOULD COMPRISE;</h3>
                            <ol type="circle">
                            <li>Previous permit on existing building</li>
                            <li>Four (4) new copies of block and site plan to scale of 1/20 or 1/40 showing the position of the building(s) and other works on site.</li>
                        </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069", fontStyle:"italic"}}>NOTE:</h3>
                    <p>You may have to provide the following reports if your development is a multi-storey structure or complex: </p>
                <ol type="circle">
                    <li>Fire Report and appropriate fire engineering drawing duly vetted and approved by Ghana Fire Service (3 copies).</li>
                    <li>Geo-technical (Soil investigation) Report (3 copies)</li>
                    <li>Structural Integrity Report where the vertical extensions are proposed on existing building (3 copies).</li>
                    <li>Traffic Impact Assessment Report (3 copies).</li>
                    <li>Hydrological Report and appropriate drawings (3 copies).</li>
                    <li>Structural calculation / Analysis Report (2 copies).</li>
                    <li>Environment Impact Assessment Report (3) (if required).</li>
                    <li>Any other document as may be necessary.</li>
                    <p>You can contact the Physical Planning Department offices, for advice and assistance)</p>
                </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>4.	Procedures</h3> 
                <ol type="i">
                    <li>The Schedule Officer will inspect the site with the developer to ensure that the site is one shown on the site plan and that site conditions are suitable for the proposed development.</li>
                    <li>The District Works Engineer carries out preliminary vetting of architectural drawing </li>
                    <li>The Physical planner process the application for the consideration of technical committee meeting.</li>
                    <li>Technical committee meets to evaluate the application, visits site and make recommendation to the Statutory Planning Committee (SPC) within one (1) month of receipt of application.</li>
                    <li>Statutory Planning Committee considers development applications within fourteen (14) days of technical committee meeting.</li>
                    <li>Approved plans are sent to the Works Engineer and the Physical Planning Officer for signing of building permit within five (5) working days.</li>
                    <li>Pay approved building permit fee to the cash office of the assembly and collect your development permit from the Physical Planning office thirty (30) days after submission of application.</li>
                </ol>
                <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069", fontStyle:"italic"}}>Please Note the Following:</h3>
                <ol type="i">
                    <li>Receipt issued as payment for processing fee is NOT a building permit.</li>
                    <li>Fees charged by the Assembly are subject to change and regulated by fee fixing resolution adopted by the Assembly annually.</li>
                    <li>Your building must conform to the approved planning scheme.</li>
                    <li>Your building permit is valid for only five (5) years.  Applicants who do not start the project within five (5) years must apply for renewal of permit?</li>
                    <li>Permit can be obtained within thirty (30) days subject to proper title to land and standard drawings.</li>
                    <li>Do not make any false declaration on your application else your application shall be rejected.</li>
                </ol>
        </div>
        </div>
        <Footer/>
        </>
    )
 }