import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/OSEI.jpg"

export default function Physical(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
         <h1 style={{textAlign:"center", color:"#008069"}}>PHYSICAL PLANNING</h1>
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>KWADWO OSEI HWEDEI</h3>
            <p style={{textAlign:"center"}}><b>HEAD, PHYSICAL PLANNING DEPT.</b></p>
            </div>
              <div  style={{marginRight:"100px", marginLeft:"100px",marginBottom:"10px", fontSize:"20px", justifyContent:"justify"}}>
              <div>
            <p>The Physical Planning Department is one of the departments of the District Assembly whose functions are crucial for effective implementation of Physical Planning related policies and programmes in the District. The department makes sure that all physical buildings and spatial features are in a proper order and the necessary measures are put in place to ensure a well-organized spatial integration in accordance with sound environmental and planning principles.</p>
            <p>The long-term objective of the department is to promote a sustainable, spatially integrated, balanced and orderly development of human settlements within the District.</p>
            <h2 style={{fontWeight:"bold"}}>Objectives</h2>
            <p>The department has laid down objectives it seeks to achieve. These objectives are:</p>
            <ol>
                <li>To provide for the planning and the sustainable development of land and human settlements in the District.</li>
                <li>To ensure the judicious use of land in the District.</li>
                <li>Promote a sustainable, spatially integrated and orderly development of human settlements.</li>
                <li>To provide the enabling environment through technical guidance from the Land Use and Spatial Planning Authority for District Assemblies to better perform the spatial planning and human settlement management functions.</li>
                <li>Strengthen the human and institutional capacities for effective land use planning and management.</li>
                <li>To preserve and enhance the environment through planting and proper cultural practices for green plants including shade trees, shrubs and turf grass.</li>
                <li>To cultivate and promote research, education and development of horticulture.</li>
                <li>Create database of streets and properties in the District.</li>
                <li>To enhance quality of life through ecosystem services, production of safe and nutritious food products.</li>
            </ol>
            </div>
            </div>
           <Footer/>
           </div>
        </>
    )
}