import React from "react"
import { Link } from 'react-router-dom'
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"



export default function Statistics(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
                  <h2 style={{textAlign:"center", color:"#008069", textDecorationLine:"underline"}}>STATISTICS DEPARTMENT</h2>
              <div>
                <Link to="/agric" className="btn btn-primary">AGRIC DEPARTMENT</Link>
                <Link to="/central" className="btn btn-primary">CENTRAL ADMINISTRATION</Link>
                <Link to="/education" className="btn btn-primary">EDUCATION DIRECTORATE</Link>
                <Link to="/finance" className="btn btn-primary">FINANCE</Link>
                <Link to="/health" className="btn btn-primary">HEALTH DIRECTORATE</Link>
                <Link to="/hr" className="btn btn-primary">HUMAN RESOURCE DEPARTMMENT</Link>
                <Link to="/nadmo" className="btn btn-primary">NADMO</Link>
                <Link to="/ physical " className="btn btn-primary">PHYSICAL PLANNING DEPARTMENT</Link>
                <Link to="/statistics " className="btn btn-primary">STATISTICS DEPARTMENT</Link>
                <Link to="/social" className="btn btn-primary">SOCIAL WELFARE & COMM. DEV’T</Link>
                <Link to="/works" className="btn btn-primary">WORKS DEPPARTMENT</Link>
             </div>
             </div>
         <Footer/>
        </>
    )
    }
    
