import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"

export default function Mission(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>MISSION & VISION</h1>
        <div style={{marginLeft:"100px", marginRight:"50px", fontFamily:"sans-serif", fontSize:"25px"}}>
       <div>
        <h2 align="center" style={{color:"#008069", textDecorationLine:"underline"}}>MISSION OF AMANSIE WEST DISTRICT ASSEMBY</h2>
        <p>The Amansie West District Assembly exist to improve the quality of life of the poeple through the formulation and implementation of the centered policies, programmes and projects in partnership with the Private Sector, Civil Society Organizations(CSO's), Non-Governmental Organizations (NGO's) and the active participation of the communities to achieve a sustanable development.</p>
        <h2 align="center" style={{color:"#008069", textDecorationLine:"underline", marginTop:"50px"}}>VISION OF AMANSIE WEST DISTRICT ASSEMBY</h2>
        <p>To make sure the standard of living of it's inhabitants is improved through a human administration and develop oriented programmes to reduce poverty and enhance access to social services.</p>
        
        <h2 align="center" style={{color:"#008069", textDecorationLine:"underline", marginTop:"50px"}}>SERVICE DELIVRY STANDARDS</h2>
        <div>
        <ol>
            <li> Participation</li>
            <li> Professionalism</li>
            <li> Accountability </li>
            <li> Client focous</li>
            <li> Transparency</li>
            <li> Efficient and Effective use of Resources</li>
        </ol>
        </div>
        
        <h2 align="center" style={{color:"#008069", textDecorationLine:"underline", marginTop:"50px"}}>CORE VALUES</h2>
        <div>
        <ol>
            <li> Client-oriented</li>
            <li> Creativity</li>
            <li> Participation</li>
            <li> Diligence</li>
            <li>Discipline</li>
            <li> Anonymity</li>
            <li>Equity</li>
            <li>Loyalty</li>
            <li>Commitment</li>
            <li>Impartiality</li>
            <li>Permanence</li>
            <li>Innovativeness</li>
            <li>Timeless and Transparency</li>
        </ol>
        </div>
<h2 align="center" style={{color:"#008069", textDecorationLine:"underline", marginTop:"50px"}}>
        CORE FUNCTIONS OF THE AMANSIE WEST DISTRICT ASSEMBLY
        </h2>
        <p>For the purpose of achieving its objectives, the Assembly has, in reference to Article 245 of the 1992 Constitution, the local Governance Act 936 and 1.11403 which established the District performs the following functions:</p>
        <p>
    <ol>
        <li>Exercises political and Administrative authority in the District, provide guidance, give direction to, and supervise the other administrative authority in the District.</li>
        <li>Performs deliberative, legislative and executive functions.</li>
        <li>Responsible for the overall development of the District and ensures the preparation and submission through the regional Coordinating council;</li>
        <p style={{marginLeft:"40px"}}>I.	To prepare Medium Term Development Plan of the District to the national Development Planning commission for approval.</p>
        <p style={{marginLeft:"40px"}}>II.	Prepare Annual Budget of the District related to the Approved District Medium Term Development Plan to the Minister responsible for finance for approval.</p>
        <p style={{marginLeft:"40px"}}>III.	Formulate and execute plans, programmes and strategies for the effective mobilization of the resources necessary for the overall development of the district.</p>
        <p style={{marginLeft:"40px"}}>IV.	Promote and support productive activity and social development in the District and remove any obstacles to development;</p>
        <p style={{marginLeft:"40px"}}>V.	Initiate programmes or the development of basic infrastructure and provide Municipal works and services in the District</p>
        <p style={{marginLeft:"40px"}}>VI. Responsible for the development, improvement and management of Human settlements and the   Environment in the district.</p>
        <p style={{marginLeft:"40px"}}>VII. Responsible for co-operation with the appropriate national and local security agencies, for the maintenance of security and public safety in the District.</p>
        <p style={{marginLeft:"40px"}}>VIII. Ensure ready access to Courts in the district for the promotion of Justice;</p>
        <p style={{marginLeft:"40px"}}>IX. Initiate, sponsor or carry out studies that is necessary for the performance of a function conferred by this Act or by any other enactment, and</p>
        <p style={{marginLeft:"40px"}}>X. Perform any other functions provided for, under any other enactment.</p>
        <li>Initiates programmes for the development of basic infrastructure in the District.</li>
        <li>Responsible for the maintenance of security and public safety in co-operation with appropriate national and local security agencies.</li>
        <li>Ensure ready access to the courts and public tribunals in the district for the promotion of justice.</li>
    </ol>
</p>
</div>
       </div>
       </div>
        <Footer/>
        </>
    )
}
