import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/chesoo.jpg"

export default function Social(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069", marginBottom:"500px"}}>PROGRAMMES</h1>
        
        </div>
        <Footer/>
        </>
    )
}