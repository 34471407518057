import React, { useEffect, useState } from 'react'
import {Box, Row, Column, FooterLink, Heading} from "./FooterStyle"
import {BatteryCharging20, Call, Email, Facebook, FaceRounded, GpsFixedRounded, GpsFixedTwoTone, Home, Instagram, LinkedIn, LocalActivity, LocalActivitySharp, LocalGasStation, LocalPostOffice, LocationCity, LocationCityOutlined, LocationOn, LocationSearching, Twitter, YouTube} from "@material-ui/icons"
import "./Footer.css"
export default function Footer() {
    const[uiWidthState, setUiWidthState] = useState(true)
    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

            if(window.innerWidth>960){
                setUiWidthState(true)
            }else{
                setUiWidthState(false)
            }
          
      }
      
          window.addEventListener('resize', handleResize)
    }, [])
    return (
            <Box style={{backgroundColor:"black"}}>
                <div style={{justifyContent:"center", alignItems:"center", marginLeft:"120px", marginRight:"0px", backgroundColor:"black"}}>
                        <Row style={{display:"flex", flexDirection:uiWidthState ?"row" : "column"}}>
                            <div style={{flex:1.6, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}>ABOUT US</Heading>
                                <FooterLink href="/home">Home</FooterLink>
                                <FooterLink href="/profile">Profile</FooterLink>
                                <FooterLink href="/mission">Mission</FooterLink>
                                <FooterLink href="/mission">Vision</FooterLink>
                                <FooterLink href="">The Assembly</FooterLink>
                            </div>
                            <div style={{flex:3.5, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}>OUR CONTACTS</Heading>
                                <FooterLink href="/contact"><Home style={{marginRight: "10px"}}/> P. O. Box 1, Manso-Nkwanta</FooterLink>
                                <FooterLink href="/contact"><Email style={{marginRight: "10px"}}/> info@amansiewestda.gov.gh</FooterLink>
                                <FooterLink href="/contact"><Call style={{marginRight: "10px"}}/>+233(0)322-490947</FooterLink>
                                <FooterLink href="/contact"><LocationOn style={{marginRight: "10px"}}/>AW-0001-7236</FooterLink>
                            </div>
                            <div style={{flex:3, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}>OUR SERVICES</Heading>
                                <FooterLink href="/businessOperatingPermit">Business Operating Permit</FooterLink>
                                <FooterLink href="/buildingPermit">Building Permit</FooterLink>
                                <FooterLink href="/marriageRegistration">Marriage Registration</FooterLink>
                            </div> 
                            <div style={{flex:4, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}> OTHER LINKS</Heading>
                                <FooterLink href="https://lgs.gov.gh">Office of Head of Local Gov't Service</FooterLink>
                                <FooterLink href="http://www.mlgrd.gov.gh">Ministry of Local Government & Rural Dev't</FooterLink>
                                <FooterLink href="https://mofep.gov.gh">Ministry of Finance</FooterLink>
                                <FooterLink href="https://ghaudit.org">Audit Service</FooterLink>
                            </div>
                        </Row >
                        {/* <Heading style={{marginTop:"2px", textDecoration:"underline", textAlign:"center", color:"gold", marginRight: "30px"}}>FOLLOW US ON THE FOLLOWING SOCIAL MEDIA HANDLES</Heading>
                        <Row style={{fontSize:"50px", display: "grid", flexDirection:"row", gridTemplateColumns:"repeat(5, auto)", gridGap:"160px", marginBottom:"20px"}}>
                            <Facebook style={{ backgroundColor:"white", fontSize:"50px", borderRadius:"60px", color:"#008069"}}/>
                            <Instagram style={{ backgroundColor:"white",fontSize:"50px", borderRadius:"60px", color:"#008069"}}/>
                            <Twitter style={{ backgroundColor:"white",fontSize:"50px", borderRadius:"60px", color:"#008069"}}/>
                            <LinkedIn style={{ backgroundColor:"white",fontSize:"50px", borderRadius:"60px", color:"#008069"}}/>
                            <YouTube style={{ backgroundColor:"white",fontSize:"50px", borderRadius:"60px", color:"#008069"}}/>
                        </Row> */}
                        <div style={{flex:0.8, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"2px", textDecoration:"underline", textAlign:"center", color:"gold", marginRight: "5px", marginBottom:"2px"}}>FOLLOW US</Heading>
                            <div style={{ alignItems:"flex-end", fontSize:"50px", display: "grid", flexDirection:"row", gridTemplateColumns:"repeat(4, auto)", gridGap:"50px", marginBottom:"20px", marginTop:"10px"}}>
                            <Facebook href="https://facebook.com/amansiewestdistrictassembly" style={{ backgroundColor:"white", fontSize:"40px", borderRadius:"60px", color:"#008069", marginRight: "7.5px"}}/>
                            <Instagram href="https://instagram.com/amansiewestda" style={{ backgroundColor:"white",fontSize:"40px", borderRadius:"60px", color:"#008069", marginRight: "7.5px"}}/>
                            <Twitter href="https://twitter.com/amansiewest"style={{ backgroundColor:"white",fontSize:"40px", borderRadius:"60px", color:"#008069", marginRight: "7.5px"}}/>
                            <YouTube href="https://youtube.com/amansiewestda" style={{ backgroundColor:"white",fontSize:"40px", borderRadius:"60px", color:"#008069", marginRight: "7.5px"}}/>
                            </div>
                            </div>
                    <div>
                  
                <a style={{textAlign:"center", textDecoration:"italic", marginBottom: "5px", marginTop: "0px", color:"#fff", marginLeft:"0px"}} class='website-rights'><strong style={{textAlign:"center"}}>Copyright © 2018 - 2022. AMANSIE WEST DISTRICT ASSEMBLY.  All rights reserved   ||   Designed by the MIS Unit - 0203365586</strong></a>
            
                </div>
                </div>
            </Box>
    )
}