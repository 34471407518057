import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/ENG.jpg"

export default function Works(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>WORKS DEPARTMENT</h1>
        
            <div style={{ display:"flex",  marginTop:"20px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR. KIRK K. KUDOZIA </h3>
            <p style={{textAlign:"center"}}><b>DISTRICT ENGINEER</b></p>
             <div style={{marginLeft:"50px", marginRight:"50px", fontFamily:"sans-serif", fontSize:"20px"}}>
             <p style={{marginLeft:"50px"}}>The Department of Works of the Assembly is a merger of the Public works Department, Department of Feeder Roads and District Water & Sanitation Units, Department of Rural Housing and the Works Unit of the Assembly.
           The Works Department;</p>
            <h1 style={{marginLeft: "50px", fontSize:"20px", marginRight:"50px"}}> The Works Department;</h1>
            <h3 style={{marginLeft: "50px", fontSize:"20px", marginRight:"50px"}}> Facilitate the implementation of policies on works and report to the Assembly, and provide advice on matters relating to Works in the Assembly.</h3>
         <h3 style={{marginLeft: "50px", fontSize:"20px", marginRight:"50px"}}>Facilitate the construction, repair and maintenance of:</h3>
         
        <ol type="1" style={{marginLeft: "100px", fontSize:"20px", marginRight:"50px"}}>
            <li>Facilitate the implementation of policies on works and report to the Assembly, and provide advice on matters relating to Works in the Assembly. </li>
        <li>Facilitate the construction, repair and maintenance of: </li>
        <li>Public roads including Feeder Roads. </li>
        <li>Drains along any streets in the major settlements within its jurisdiction. </li>
        <li>Encourage and facilitate maintenance of public buildings and facilities in the Assembly. </li>
        <li>Assist to build, equip, close and maintain markets and prohibit the erection of stores in places other than the markets. </li>
        <li>Assist to inspect projects undertaken by the District Assembly with the relevant departments of the Assembly. </li>
        <li>Advise the Assembly on the prohibition of digging of burrow pits and other evacuations in the sinking of wells or their closers. </li>
        <li>Assist to maintain public buildings made up of offices, residential accommodations and ancillary structures. </li>
        <li>Advise and encourage owners of building structures to; 
        <ol type="a">
            <li>Remove dilapidated structures in any public place. </li>
            <li>Paint, distemper, white wash or colour wash the outside of any building forming part of the premises</li>
            <li>Tidy up the premises and remove any derelict vehicles or objects which constitute nuisance. </li>
        </ol></li>
            <li>Provide technical advice for the machinery and structural layout of building plans to facilitate escape from fire, rescue operation and fire management</li>

        </ol>
        </div>
            </div>
            </div>
        <Footer/>
        </>
    )
}