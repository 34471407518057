import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/chesoo.jpg"

export default function Social(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>SOCIAL WELFARE AND COMMUNITY DEVELOPMENT</h1>
        <div style={{ display:"flex",  marginTop:"20px",  marginBottom:"10px", justifyContent:'center', alignSelf:'center', marginRight:"50px", marginLeft:"50px", fontSize:"20px"}}>
            <div >
            <img src={pic} style={{alignItems:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", border:"3px solid #008069", margin: "auto", marginRight:"10px" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>CHESLYN E. NUTSUGAH</h3>
            <h4 style={{textAlign:"center", fontType:"bold", color:"#000000", marginTop:"2px"}}>DEPARTMENTAL HEAD</h4>
            </div>
            </div>
            <div style={{textColor:"black", fontFamily:'sans-serif', fontSize:'20px', marginLeft:'100px', marginRight:'100px'}}>
                <p>The Social Welfare and Community Development Department is established at MMDA's as per Second Schedule of the Local Governance Act, 2016 (Act 936). The Department of Social Welfare and Community Development is one of the decentralized Departments under the Local Government service of Ghana. It is headed by District Officers at the various Metropolitan, Municipal and District Assemblies where information flows upwards from the MMDA’S through the MCE’S / DCE’s through to the Regions and to the Ministerial levels at the national levels and vice versa.</p>
                <h2 style={{textAlign:"center", textDecoration:"underline", marginBottom:"10px", color:"#008069"}}>OVERVIEW OF DEPARTMENT </h2>
            <h2 style={{textAlign:"LEFT", color:"#008069", }}>Vision</h2>
            <p>To take the lead in integrating the disadvantaged, vulnerable and excluded in mainstream of development.</p>
            <h2 style={{textAlign:"LEFT", color:"#008069", }}>Mission</h2>
            <p>The SWCD Department work in partnership with individuals, families, groups and communities to improve their social wellbeing through their active participation in promoting development with equity.</p>  
            <p></p>
            <h2 style={{textAlign:"LEFT", color:"#008069", }}>Objectives</h2>
            <p>The Social Welfare and Community Development Department exist to;</p>
            <ol type="a">
            <li>Facilitate the mobilization and use of available human and material resources to improve the living standards of individuals, groups, families and communities within an effectively decentralised system of administration. </li>
            <li> Prevent and respond to social exclusion and mal adjustment within the context of national and sub national development efforts</li>
            </ol>
            <div>
            <p><b>The core functions and responsibilities of Department of Social Welfare and Community Development includes the following;</b></p>
            
            <ol type="1">
                <li>Facilitate community-based rehabilitation of persons with disabilities;</li>  
                <li>Assist and facilitate provision of community care services including: </li>
                <ol type="i">
                    <li>registration of persons with disabilities;</li>
                    <li>assistance to the  elderly;  </li>
                    <li>personal social welfare services;  </li>
                    <li>hospital welfare services;  </li>
                    <li>assistance to street children, child survival and development; and </li> 
                    <li>socio-economic and emotional stability in families;  </li>
	            </ol>
                <li>Assist to maintain specialized residential services in the districts;  </li>
                <li>Facilitate the registration and supervision of non-governmental organizations and their activities in the district;  </li>
                <li>Justice administration – assist children and adults in contact and conflict with the law; </li>
                <li>Register and supervise early childhood development centres; </li>
                <li>Assist to organize community development programmes to improve and enrich standard of living through: </li> 
                <ol type="i">
                    <li>Organise literacy and adult education programme; </li>
                    <li>Mobilise voluntary contributions and communal labour for the provision of facilities and services such as water, schools, library, community centres and public places of convenience;  </li>
                    <li>Teach deprived women in home management, child care  </li>
                    <li>Offer employable and sustainable skills training for the youth. </li> 
                    <li>Promote and provide alternative livelihood empowerment skills for the deprived in the communities  </li>
                </ol>
                <li>Provide extension services to governmental and non-governmental organisations </li>
                <li> Facilitate the roll out of the Child Protection tool kits through community engagement and dialogue. </li>
                <li>Facilitate the formation of structures to help address the provision and management of water facilities in all communities in the district. </li>
            </ol>
           </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}