import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/DFO.png"

export default function Health(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>HEALTH DIRECTORATE</h1>
        <p align="center">There are 1 Poly Clinic, 4 Health Centres, 3 CHPS Compounds, 2 Maternity Homes and 29 CHIPS Zones taking care of health care delivery in the district.</p>
        
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px",display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR. BERNARD BEDIAKO</h3>
            <p style={{textAlign:"center"}}><b>DISTRICT HEALTH DIRECTOR</b></p>
            </div>
            </div>
        <Footer/>
        </>
    )
}