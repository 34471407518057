import React from "react";
import pic from "../Images/REGMAP.jpg"
import Banner from "../Resources/Banner";
import Navbar from "../Resources/Navbar";
import Footer from "../Resources/Footer";

const profile=()=>{
    return (
        <div>
            
            <Banner/>
            <Navbar/>
            <div className="container" style={{background:"white"}}>
            <h1 style={{ display:"flex", textAlign:"center", marginTop:"20px", color:"#008069", justifyContent:'center',  alignSelf:'center'}}>PROFILE OF AMANSIE WEST DISTRICT</h1>

            <div style={{ display:"flex",  marginTop:"0px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            <img src={pic} style={{ width:"100%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", margin: "auto"}}/>
            </div>

            <div ClassName="card" style={{marginLeft:"100px", marginRight:"100px"}}>
                <p ClassName="container">
		<h2 align="center" style={{ color:"#008069", marginBottom:"2px", marginTop:"10px"}}>LOCATION AND SIZE</h2>
            <div style={{marginLeft:"0px", fontSize:"20px"}}>
        	<p>The Amansie West District Assembly is one of the forty- three (43) Assemblies located in the Ashanti Region. It was established by Legislative Instrument (L. I.) 1403 in 1988 as part of Ghana's Decentralization policy to bring governance to the doorsteps of the citizenry. It was further divided into Amansie West and South by Legislative Instrument (L. I.) 2363 in 2018 with the former maintaining its original name.</p>
		<p>The District shares common boundaries with six districts namely: Atwima Nwabiagya South and Atwima Kwanwoma to the north, Atwima Mponua to the west, Bekwai Municipality to the east, Amansie Central and Amansie South to the South. Specifically, the District is located within latitude 6.05° West: 6.35° North: 1.40° South and 2.05° East. It spans an area of about 522 square Kilometres.</p>
		<p>The District has a total of about 65 communities with Manso Nkwanta as the District Capital. The District Capital, Manso Nkwanta is about 65 Km from Kumasi, the Regional Capital. Apart from Manso Nkwanta, other dominant settlements include Abore, Akropong, Ahwerewa, Antoakrom, Esaase, Essuowin, Mpatuam, Moseaso, Nsiana, Manso Atwere, Yawkrom, Pakyi No. 1 and 2.</p>
		<p >The surrounding Districts with respect to this location provide opportunity for marketing goods and services from the district. The location of the district makes it the centre attraction to both local and international tourists. This has a great potential for promoting hospitality to agricultural land for promotion of citronella, cocoa, oranges and oil palm plantations to feed the local agro-based industries and beyond.</p>
	    </div>
        <h2 align="center" style={{ color:"#008069", marginBottom:"2px"}}>POPULATION/GROWTH RATE/DISTRIBUTION</h2>
            <div style={{marginLeft:"0px", fontSize:"20px"}}>
            <p >The male and female populations stand at 56,048 and 53,368 respectively. The population of the District is more rural in nature with fifty-six thousand and forty-eight (56,048) persons representing 51.23% and fifty-three thousand, three hundred and sixty-eight (53,368) representing 48.77%. The District has a population density of 209.61 p/ km². This situation even though not alarming, the effort is required to keep it in check to avoid pressure on both natural resources and other physical infrastructure.</p>
            </div>
            <h2 align="center" style={{ color:"#008069", marginBottom:"2px"}}>POLITICAL ADMINISTRATION (Sub-District Structures)</h2>
             <div style={{marginLeft:"0px", fontSize:"20px"}}>
                <p>The District has been divided into Seven (7) Area Councils. The Area Councils have been reconstituted in accordance with L.I. 1967. All the area councils have offices being taking care of by Admin officer of the Assembly and the council chairmen. The District has twenty-seven (27) electoral Areas. Each Electoral Area has one (1) Assembly member and five (5) Unit committee members. The Assembly has 41 Assembly members made up of 27 elected and 12 appointed members, one District Chief Executive and One Member of Parliament. The Member of Parliament is an ex-officio Member of the Assembly.</p>
                <p>The Assembly has 41 Assembly members made up of 27 elected and 12 appointees, one District Chief Executive and one Mermber of Parliament as an ex-officio member of the Assembly</p>
             <p>By law, the Assembly is supposed to meet at least 3 times in a year. The Assembly has five Statutory Sub committees including; Justice and Security, Development planning, Finance and Administration, Social Services and Works and two (3) other non-statutory Sub-Committees Agriculture and Local Economic Development, Environment and Mining.</p>
             </div>
             <h2 align="center" style={{ color:"#008069", marginBottom:"2px"}}>ECONOMIC ACTIVITY</h2>
             
                <div style={{marginLeft:"px", fontSize:"20px"}}>
		<h2 align="left" style={{ color:"#008069", marginBottom:"2px"}}>Agriculture</h2>
		<p>The District's economy is regarded as agrarian due to contribution of agriculture, forestry and fishing accounting for 59.2 percent of the district economy.</p>
		
               <h2 align="left" style={{ color:"#008069", marginBottom:"2px"}}>Tourism</h2>
		<p>The District has two (2) major forest reserves with splendid plant and wildlife species which can be harnessed for tourism development. These are the Jimira and the Gyeni Forest Reserves. It also has Rocky Hills with natural carvings located at Abore.</p>
		<h2 align="left" style={{ color:"#008069", marginBottom:"2px"}}>Industries</h2>
		<p>A few agro-industrial activities are done in the district. They include cassava processing (Gari making), oil extraction, soap making akpeteshie distilling, wood processing into lumber, furniture production, wood carving jewelry production, and metal fabricating.</p>
		<h2 align="left" style={{ color:"#008069", marginBottom:"2px"}}>Service</h2>
		<p>The Service economy is made up of both the informal and the formal. The informal economy which comprises hairdressers, barbers, drivers, painters, market women/ traders, etc is scattered in various communities in the district. Their role is however, complimented by the formal sector through the services provided by departments of the District Assembly and other government organizations such as the Police and the Courts.</p>

                <h2 align="left" style={{ color:"#008069", marginBottom:"2px"}}>Mineral Deposite</h2>
		<p>Among the resources identified in the district are potential rich mineral (gold) deposits that are found in almost every community within the Amansie West District.</p>
             </div>
    </p>
        </div>
        </div>
            <Footer/>
        </div>
    )
}
export default profile