import { Card } from "@material-ui/core"
import React from "react"
import pic from "../Images/Marriage.jpg"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import ServiceItem from "../Resources/ServiceItem"

export default function MarriageRegistration(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <div className="container">
        <ServiceItem heading="MARRIAGE REGISTRATION" text="" src={pic}/>
        </div>
<div  style={{marginLeft:"50px"}}>
        <h2 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>MARRIAGE REGISTRATION PROCESS</h2>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 1 - HOW TO APPLY</h3>
       
            <p>One or both of the prospective couples can start the process by making their intensions known to the Marriage Registrar by a written letter or word of mouth in person</p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 2 - 21 DAYS NOTICE OF MARRIAGE</h3>   
       
        <h4>NB: Particulars needed for the Notice</h4>
            <ul typeof="I">
                <li>
                	Voter's Identification Card or National Identification Card (Ghana Card).
                </li>
                <li>
                	Date of Birth of both parties.
                </li>
                <li>
                	Hometown of both parties.
                </li>
                <li>
                	Occupation of both parties.
                </li>
            </ul>
            <p>NOTE: Notice will be posted on the notice board of the Assembly's and the parties Church notice board</p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 3 - CERTIFICATE OF MARRIAGE</h3>   
        
        <p>After the 21 days, the marriage certificate is issued to the parties</p>
</div>
        </div>
        <Footer/>
        </>
    )
}