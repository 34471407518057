import React from 'react'
import CardItem from "../Resources/CardItem"
import pic1 from "../Images/PM1.jpg"
import pic2 from "../Images/DCE1.jpg"
import pic3 from "../Images/OBT.jpg"
import pic4 from "../Images/DCD.jpg"
import "../Resources/Card.css"
import Card from "../Resources/Card"
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

export default function AssemblyHome(props) {

    return (
        <div>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", placeItems:"center", marginTop:"20px", marginBottom: "10px" }}>
            <h1 style={{color:"#008069",  position: "center"}}>THE ASSEMBLY</h1>
            </div>
            <div className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-3'>
                    <Card srcPath={pic1} label="PRESIDING MEMBER" text="HON. DOMINIC KWABENA AGYEI - PRESIDING MEMBER"path="/assembly"/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic2} label=" DISTRICT CHIEF EXECUTIVE" text="HON. NII LARTEH OLLENU - DISTRICT CHIEF EXECUTIVE" path="/assembly"/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic3} label="MEMBER OF PARLIAMENT" text="HON. GEORGE OBENG TAKYI - MEMBER OF PARLIAMENT" path="/assembly"/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic4} label="DISTRICT CO-ORDINATING DIRECTOR" text="MR. KANYEBUI TIMOTHY FORDJOUR - DISTRICT CO-ORDINATING DIRECTOR"path="/assembly"/>
                </div>
            </div> 
        </div> 
        </div> 
    )
}
