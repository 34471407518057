import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import ProjectModel from "../Pages/ProjectModel"

export default function Mission(){
    const gh = ["https://firebasestorage.googleapis.com/v0/b/amansie-westda.appspot.com/o/News_Pic%2FImage4?alt=media&token=88dbb9b3-b0b0-4119-892c-4dcb314ceb9c",
    "https://firebasestorage.googleapis.com/v0/b/amansie-westda.appspot.com/o/News_Pic%2FImage8?alt=media&token=c994f7c5-c1d4-46a6-a5bb-f10722dab805",
        "No Image", "No Image", "No Image" ]
    const gh1 = ["https://firebasestorage.googleapis.com/v0/b/amansie-westda.appspot.com/o/News_Pic%2FImage4?alt=media&token=88dbb9b3-b0b0-4119-892c-4dcb314ceb9c",
    "https://firebasestorage.googleapis.com/v0/b/amansie-westda.appspot.com/o/News_Pic%2FImage8?alt=media&token=c994f7c5-c1d4-46a6-a5bb-f10722dab805",
        "No Image", "No Image", "No Image" ]
    return(
        <>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>ON-GOING PROJECT</h1>
        <ProjectModel
        imagez = {gh}
        project_name = "Connstruction of "
        project_text = "kfdlgkdfgjfdjkgljkfdjgfdg gnfdkgfdngfdlgndfg dfjgkdfjgkdfkjgsfndgfd gfdgnfdglsd"/>
        <h2 align="center" style={{backgroundColor:'black', height:'5px', color:"#008069", marginBottom:"20px", marginTop:"20px"}}></h2>
        <ProjectModel
        imagez = {gh1}
        project_name = "Connstruction of "
        project_text = "kfdlgkdfgjfdjkgljkfdjgfdg gnfdkgfdngfdlgndfg dfjgkdfjgkdfkjgsfndgfd gfdgnfdglsd"/>
        <h2 align="center" style={{backgroundColor:'black', height:'5px', color:"#008069", marginBottom:"20px", marginTop:"20px"}}></h2>
        </div>
        <Footer/>
        </>
    )
}



      