import Banner from "../Resources/Banner";
import Footer from "../Resources/Footer";
import Navbar from "../Resources/Navbar";



export default function ProjectModel(props){


    return(
        <div>
        <div style={{backgroundColor:"#e4f3e1"}}>
        
        

        <h1 style={{ display:"flex", textAlign:"center", marginTop:"20px", color:"#008069", justifyContent:'center',  alignSelf:'center'}}>{props.project_name}</h1>

        <div style={{display:'flex', flexDirection:'row', margin:"10px"}}>
        {props.imagez[0]!=="No Image"?<div style={{ display:"flex", flex:1, padding:"10px", marginTop:"0px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
        <img src={props.imagez[0]} style={{ width:"100%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", margin: "auto"}}/>
        </div>:null}
        {props.imagez[1]!=="No Image"?<div style={{ display:"flex", flex:1, padding:"10px", marginTop:"0px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
        <img src={props.imagez[1]} style={{ width:"100%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", margin: "auto"}}/>
        </div>:null}
        {props.imagez[2]!=="No Image"?<div style={{ display:"flex",flex:1, padding:"10px", marginTop:"0px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
        <img src={props.imagez[2]} style={{ width:"100%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", margin: "auto"}}/>
        </div>:null}
        {props.imagez[3]!=="No Image"?<div style={{ display:"flex", flex:1,padding:"10px", marginTop:"0px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
        <img src={props.imagez[3]} style={{ width:"100%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", margin: "auto"}}/>
        </div>:null}
        {props.imagez[4]!=="No Image"?<div style={{ display:"flex", flex:1, padding:"10px" , marginTop:"0px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
        <img src={props.imagez[4]} style={{ width:"100%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", margin: "auto"}}/>
        </div>:null}
        </div>

        <div ClassName="card" style={{marginLeft:"100px", marginRight:"100px"}}>
            <p ClassName="container">
    <h2 align="center" style={{ color:"#008069", marginBottom:"2px", marginTop:"10px"}}>-----------</h2>
        <div style={{marginLeft:"50px", fontSize:"20px"}}>
        <p>{props.project_text}</p>
    </div>
     
</p>
    </div>

    </div>
    </div>
    )
}