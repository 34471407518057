import React, {useState} from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import FARM1 from "../Images/FARM1.JPG"
import FARM2 from "../Images/FARM2.JPG"
import FARM3 from "../Images/FARM3.JPG"
import FARM4 from "../Images/FARM4.JPG"
import FARM5 from "../Images/FARM5.JPG"
import FARM6 from "../Images/FARM6.JPG"
import FOOD from "../Images/FOOD.JPG"
import CHIEFS from "../Images/CHIEFS.png"
import HEALTH from "../Images/HEALTH.png"
import LITTLEGIRL from "../Images/LITTLEGIRL.JPG"
import MPP from "../Images/MPP.JPG"
import NANA1 from "../Images/NANA1.JPG"
import NANA2 from "../Images/NANA2.JPG"
import NANA3 from "../Images/NANA3.JPG"
import NANA4 from "../Images/NANA4.JPG"
import NANA5 from "../Images/NANA5.JPG"
import NANA6 from "../Images/NANA6.JPG"
import WOMEN from "../Images/WOMEN.JPG"
import REV from "../Images/REV.jpg"
import Pic from "../Resources/Pic"
import A from "../Images/GALLERY/A.jpg"
import AA from "../Images/GALLERY/AA.jpg"
import AB from "../Images/GALLERY/AB.jpg"
import AC from "../Images/GALLERY/AC.jpg"
import AD from "../Images/GALLERY/AD.jpg"
import AE from "../Images/GALLERY/AE.jpg"
import AF from "../Images/GALLERY/AF.jpg"
import AG from "../Images/GALLERY/AG.jpg"
import AH from "../Images/GALLERY/AH.jpg"
import AI from "../Images/GALLERY/AI.jpg"
import AJ from "../Images/GALLERY/AJ.jpg"
import AK from "../Images/GALLERY/AK.jpg"
import AL from "../Images/GALLERY/AL.jpg"
import AM from "../Images/GALLERY/AM.jpg"
import AN from "../Images/GALLERY/AN.jpg"
import AO from "../Images/GALLERY/AO.jpg"
import AP from "../Images/GALLERY/AP.jpg"
import AQ from "../Images/GALLERY/AQ.jpg"
import AR from "../Images/GALLERY/AR.jpg"
import AS from "../Images/GALLERY/AS.jpg"
import AT from "../Images/GALLERY/AT.jpg"
import AU from "../Images/GALLERY/AU.jpg"
import AV from "../Images/GALLERY/AV.jpg"
import AW from "../Images/GALLERY/AW.jpg"
import AX from "../Images/GALLERY/AX.jpg"
import AY from "../Images/GALLERY/AY.jpg"
import AZ from "../Images/GALLERY/AZ.jpg"
import B from "../Images/GALLERY/B.png"
import C from "../Images/GALLERY/C.jpg"
import D from "../Images/GALLERY/D.jpg"
import E from "../Images/GALLERY/E.png"
import F from "../Images/GALLERY/F.jpg"
import G from "../Images/GALLERY/G.png"
import H from "../Images/GALLERY/H.jpg"
import I from "../Images/GALLERY/I.jpg"
import J from "../Images/GALLERY/J.jpg"
import K from "../Images/GALLERY/K.png"
import L from "../Images/GALLERY/L.jpg"
import N from "../Images/GALLERY/N.png"
import M from "../Images/GALLERY/M.jpg"
import O from "../Images/GALLERY/O.png"
import P from "../Images/GALLERY/P.png"
import Q from "../Images/GALLERY/Q.jpg"
import R from "../Images/GALLERY/R.jpg"
import S from "../Images/GALLERY/S.png"
import T from "../Images/GALLERY/T.jpg"
import U from "../Images/GALLERY/U.png"
import V from "../Images/GALLERY/V.png"
import W from "../Images/GALLERY/W.jpg"
import X from "../Images/GALLERY/X.jpg"
import Y from "../Images/GALLERY/Y.jpg"
import Z from "../Images/GALLERY/Z.jpg"
import AAA from "../Images/GALLERY/AAA.jpg"
import AAB from "../Images/GALLERY/AAB.jpg"
import AAC from "../Images/GALLERY/AAC.jpg"
import AAD from "../Images/GALLERY/AAD.jpg"


export default function Gallery(){
    const picArray=[FARM1, FARM2, FARM3, FARM4, FARM5, FARM6, FOOD, CHIEFS, HEALTH, LITTLEGIRL, MPP, NANA1, NANA2, NANA3, NANA4, NANA5, NANA6, WOMEN, REV, A, B, C, D, E, F, G, H, I, J, K, L, M, O, P, Q, R, S, T, U, V, W, X, Y, Z, AA, AAA, AAB, AAC, AAD, AB, AC, AD, AE, AF, AG, AH, AI, AJ, AK, AL, AM, AO, AP, AQ, AR, AS, AT, AU, AV, AW, AX, AY, AZ ]
    const [selectedImage, setSelectedImage] = useState(null)
    const handleClick=(e)=>{
        if (e.target.classList.contains("backdrop")){
            setSelectedImage(null)
        }
    }

    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>GALLERY</h1>
        <div>
            <div className="img-grid">
             {picArray.map((key, val)=>(
                 <div className="img-wrap" onClick={()=>setSelectedImage(picArray[val])}>
                        <img src={picArray[val]} alt=""></img>
                 </div>
             ))}  
              <div onClick={(e)=>handleClick(e)}>
                {selectedImage!==null?<Pic src={selectedImage}/>:null}
              </div>
            </div>
        </div>
        </div>
        <Footer/>
        </>
    )
}