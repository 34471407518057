import React from 'react'
import CardItem from "../Resources/CardItem"
import pic1 from "../Images/BUSINESS.jpg"
import pic2 from "../Images/building.jpg"
import pic3 from "../Images/Marriage.jpg"
import "../Resources/Card.css"
import Card from "../Resources/Card"
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Banner from "../Resources/Banner"
import Navbar from "../Resources/Navbar"

export default function Services(props) {
    return (
        <div>
            {props.hide==="true"?<div></div>:
            <div><Banner/>
            <Navbar/> </div>
            }
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", placeItems:"center", marginTop:"40px"}}>
            <h1 style={{color:"#008069",  textAlign: "center"}}>OUR SERVICES</h1>
            </div>
        <div className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} title="Business Registration" text="" path="/Business"/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic2} title="Building Permit" text="" path="/BuildingPermit"/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic3} title="Marriage Registration" text="" path="/MarriageRegistration"/>
                </div>
            </div> 
        </div>
        </div>
        // <div className='Cards'>
        //     <h1 style={{color:"#008069"}}>OUR SERVICES</h1>
        //     <div className= "cards__container">
        //     <div className='cards__wrapper'>
        //     <ul className='cards__item'>
        //         <CardItem path="/" src={pic1} label="Business Registration" text="ABCD"/>
        //         <CardItem path="/" src={pic2} label="Building Permit" text="EFGH"/>
        //         <CardItem path="/" src={pic3} label="Marriage Registration" text="IJKL"/>
        //     </ul>
        //     </div>
        //     </div>
        // </div>
    )
}
