import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/A1.jpg"

export default function Agric_Departmennt(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
            <h1 style={{textAlign:"center", color:"#008069"}}>AGRIC DEPARTMENT</h1>
            <div style={{ display:"flex",  marginTop:"20px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic}style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR. ANTHONY ADUSEI JANTUAH</h3>
            <p style={{textAlign:"center"}}><b>AGRIC DIRECTOR</b></p>
            </div>
            <div style={{marginLeft:"50px", marginRight:"50px", fontFamily:"sans-serif", fontSize:"20px"}}>
            <p>The Agricultural Department provides leadership for the development of agriculture and sustainability of the agro-environment in the Service.</p>
            <p>The department promotes policies, strategies and appropriate agricultural technologies necessary to improve agribusiness, agro processing and crop/animal/ fish production.</p>
            <p>It also organizes agricultural field schools, workshops and seminars to assess farmers’ participation and the level of adoption of new farming technologies and practices.</p>

            </div>
            </div>
        <Footer/>
        </>
    )
}