import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import Card from "../Resources/Card"
import pic1 from "../Images/PM1.jpg"
import pic2 from "../Images/DCE.jpg"
import pic3 from "../Images/OBT.jpg"
import pic4 from "../Images/DCD.jpg"
import pic5 from "../Images/HONPATRIC.jpg"

import pic7 from "../Images/NADMO.jpg"
import pic8 from "../Images/BODY.jpg"
import pic9 from "../Images/DCD.jpg"
import pic10 from "../Images/DCD.jpg"
import pic11 from "../Images/NADMO.jpg"
import pic12 from "../Images/ALEX.jpg"
import piC13 from "../Images/HON.jpg"
import pic14 from "../Images/HONAMANKWA.jpg"
import pic15 from "../Images/HONANTWI.jpg"
import pic16 from "../Images/DKM.jpg"
import pic17 from "../Images/AGOMBE1.jpg"
import pic18 from "../Images/FREDERECK.jpg"
import pic19 from "../Images/DCD.jpg"
import pic20 from "../Images/DCD.jpg"
import pic21 from "../Images/DCD.jpg"


export default function AssemblyMembers(){
    return(
        <>
        <div className="container" style={{background:"white"}}>
        <Banner/>
        <Navbar/>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", placeItems:"center", marginTop:"5px", marginBottom: "5px" }}>
            <h1 style={{color:"#008069",  position: "center"}}>THE ASSEMBLY</h1>
            </div>
            <div className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-3'>
                    <Card srcPath={pic1} label="PRESIDING MEMBER" text="HON. DOMINIC KWAKU ADJEI" more="" path="/assembly"/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic2} label=" DCE" text="HON. NII LARTEH OLLENU" more="" path="/assembly"/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic3} label="MP" text="HON. GEORGE OBENG TAKYI" more="" path="/assembly"/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic4} label="DCD" text="MR. KANYEBUI TIMOTHY FORDJOUR" more="" path="/assembly"/>
                </div>
            </div> 
        </div> 


        
    <div>
        <h1 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>ASSEMBLY MEMBERS - ELECTED</h1>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"20px"}}>AHWEREWA AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic5} label="AHWEREWA" title="HON. KUSI DICKSON" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ESSASE SOUTH" title="HON. VICTOR APPIAH" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic8} label="DOMINASE-ESSASE" title="HON. SAMPSON DANKWAH" more=""/>
                </div>
                </div>
                </div>
        </div>
        <div>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>ATWERE AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ATWERE" title="HON. AMPONSAH DARKO" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ASAMAN" title="HON. ADJEI MARFO ENOCH" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="YAWKROM" title="HON. AYENSU BARIMAH KWAME " more=""/>
                </div>
                </div>
                </div>
        </div>
        <div>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>ABORE AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic11} label="ABORE-EBOM" title="HON. SAMUEL ASARE BREW" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic8} label="BROFOYEDRU" title="HON. EMMANUEL OPPONG" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="KYENKYENEASE" title="HON. KUSI OBED" more=""/>
                </div>
                </div>
                </div> 
        </div>
        <div>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>ANTOAKROM AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
        <div>
        <div className="row">
                <div className='col-md-3'>
                    <Card srcPath={pic1} label="ABODOM" title="HON. PAUL KUSI" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic9} label="AKROPONG" title="HON. OWUSU ACHIA W.B" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic14} label="ANTOAKROM" title="HON. AMANKWAA MICHEAL" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic1} label="ASAREKROM" title="HON. DOMINIC AGYEI KWABENA" more=""/>
                </div>
        </div>

        <div className="row">
                <div className='col-md-3'>
                    <Card srcPath={piC13} label="PAKYI No.1" title="HON. WILLIAMS OBUADUM KUSI" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic12} label="PAKYI No.2" title="HON. DONKOR ALEX" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic1} label="NSIANA" title="HON. ADOMAKO JAMES" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic15} label="OMANADWAREYE" title="HON. AMPONSAH ANTWI BOASIAKO" more=""/>
                </div>
        </div>
        </div>
        </div>
        <div>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>ESSUOWIN AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
        <div>
        <div className="row">
            <div className='col-md-3'>
                    <Card srcPath={pic1} label="ATOBIASE" title="HON. APPIAH STEPHEN" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic16} label="MOSEASO" title="HON. DANIEL KWAKU MENSAH" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic17} label="HIAKOSE" title="HON. ISAAC MANU" more=""/>
                </div>
                <div className='col-md-3'>
                    <Card srcPath={pic1} label="ESSUOWIN" title="HON. PATRICK AGBEAME" more=""/>
                </div>
            </div>
            
            </div>
            </div>
            </div>
        <div>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>MANSO - NKWANTA AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ABRONKASE (MANSO - NKWANTA)" title="HON. STEPHEN ARMSTRONG DONKOR" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="EKOSO (MANSO - NKWANTA)" title="HON. GEORGE FRIMPONG" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="DOMINASE-ESSUMNYA" title="HON. DANIEL APPIAH" more=""/>
                </div>
                </div> 
                </div> 
                </div> 
        <div>
        <h2 style={{textAlign:"center", color:"#008069", marginTop:"100px", paddingBottom:"5px"}}> MPATUAM AREA COUNCIL</h2>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div  className='col-md-4'>
                    <Card srcPath={pic1} label="GENINSO" title="FREDERICK KOFI MINTAH" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic18} label="MPATUAM WEST" title="HON. NKANSAH FREDRICK" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="MPATUAM EST" title="HON. ANTHONY AMANKWAH" more=""/>
                </div>
                </div>
                </div> 
        </div>
        <div>
        <h1 style={{textAlign:"center", color:"#008069", marginTop:"100px"}}>ASSEMBLY MEMBERS - APPOINTEES</h1>
        <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="MANSO-NKWANTA" title="HON. NANA AMOAKO FOFIE III" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ABORE" title="HON. REV. HANSON DJAN AMOAKO" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ATWERE" title="HON. SAMUEL ANTWI BOASIAKO" more=""/>
                </div>
                </div>
                </div>
                <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="PAKYI No. 1" title="HON. TINA OPOKU KONADU" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="TETREM" title="HON. ANTWI LORD " more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="HIAKOSE" title="HON. NANA ANINKORA KWAME II" more=""/>
                </div>
                </div>
                </div>
                </div>
            <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="PAKYI No. 2" title="HON. IBRAHIM MUSAH MUTAWAKIL" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="PAKYI No. 2" title="HON. NANA KWAKU BOATENG" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ABODOM" title="HON. OHENEYERE ANIWAA A. ATUAMPEM II" more=""/>
                </div>
                </div>
                </div>
                <div style={{marginTop:"-80px"}} className='container-fluid d-flex justify-content-center'>
            <div className="row">
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="NSIANA" title="HON. ADDAI FRANCIS ROBERT" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="MOSEASO" title="HON. NANA KWAKU BOATENG" more=""/>
                </div>
                <div className='col-md-4'>
                    <Card srcPath={pic1} label="ANTOAKROM" title="HON. ABRAHAM APPIAH DANQUAH" more=""/>
                </div>
                </div>
                </div>
        </div>
        </div>
        <Footer/> 
        </>
    )
}