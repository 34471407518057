import react from "react"

const pic=(props)=>{
    return(
        <div className="backdrop">
            <img src={props.src} alt="selectedpic"></img>

        </div>
    )
}
export default pic


