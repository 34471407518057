import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/BUSINESS.jpg"
import ServiceItem from "../Resources/ServiceItem"

export default function Business(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div style={{backgroundColor:"#fff"}}>
        <div className="container">
        <ServiceItem heading="BUSINESS OPERATING PERMIT" text="ads" src={pic}/>
        </div>
        </div>
        <Footer/>
        </>
    )
}


