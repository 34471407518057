import React, { useState } from 'react'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import nobody from "../Images/empty.png"
import Navbar from "../Resources/Navbar"
import Footer from '../Resources/Footer'
import { Button, FormControl, Input, InputLabel, TextField } from '@material-ui/core'
import Banner from '../Resources/Banner'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { get, getDatabase, ref, child, set } from 'firebase/database'
import {getDownloadURL, getStorage, ref as refSor, uploadBytes } from 'firebase/storage'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export default function UploadNews(props) {

    const [openPopUp, setPopUp]=useState(false)
    const [topic, setTopic] = useState("")
    const [textx, setText] = useState("")
    const [dob, setDob] = useState('')
	const [ dobLab, setDoLab] = useState("")



    
	const [progress, setProgress] = useState("")
	const [Pic_Url, setPic_Url] = useState(null)
    const [Pic_Url1, setPic_Url1] = useState(null)
    const [Pic_Url2, setPic_Url2] = useState(null)
	const [imagez, setImage] = useState(null)
    const [imagez1, setImage1] = useState(null)
    const [imagez2, setImage2] = useState(null)
    const [author, setAuthor] = useState("")


	const changeHandler=(e,num)=>{
		let selected = e.target.files[0];
		const types = ['image/png', 'image/jpeg']
     
		if(selected && types.includes(selected.type)){
        if (num==="1"){
            setPic_Url(selected)
			setImage(URL.createObjectURL(selected))
        }else if (num==="2"){
            setPic_Url1(selected)
			setImage1(URL.createObjectURL(selected))
        }else if (num==="3"){
            setPic_Url2(selected)
			setImage2(URL.createObjectURL(selected))
        }
		}else{
			setPic_Url(null)

		   // setErr("Please Select an image file(png or jpeg format)")
		}
	}

    const docs = [
        { uri: "https://firebasestorage.googleapis.com/v0/b/amansie-westda.appspot.com/o/News_Pic%2FImage4?alt=media&token=88dbb9b3-b0b0-4119-892c-4dcb314ceb9c" },
       
      ];
     
    

    function checkUpload(){
        if(Pic_Url===null){
            alert("Select a picture")
        }else{
            startUpload()
        }
    }

    function convertDateForPicker(params){
        //Sun Mar 01 2015 00:00:00 GMT+0000 (GMT)
        
        var date = (params+"").split(" ");
        var day = date[2];
        var month = date[1];
        
        var year = date[3]+""
        if(month.toLowerCase()==="jan"){
            month = "01"
        }else if(month.toLowerCase()==="feb"){
            month = "02"
        }else if(month.toLowerCase()==="mar"){
            month = "03"
        }else if(month.toLowerCase()==="apr"){
            month = "04"
        }else if(month.toLowerCase()==="may"){
            month = "05"
        }else if(month.toLowerCase()==="jun"){
            month = "06"
        }else if(month.toLowerCase()==="jul"){
            month = "07"
        }else if(month.toLowerCase()==="aug"){
            month = "08"
        }else if(month.toLowerCase()==="sep"){
            month = "09"
        }else if(month.toLowerCase()==="oct"){
            month = "10"
        }else if(month.toLowerCase()==="nov"){
            month = "11"
        }else if(month.toLowerCase()==="dec"){
            month = "12"
        }
        
        var full = day+"/"+month+"/"+year

        return full

    }

    function currentDate(){
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var year = now.getFullYear() 
        if(month==="01"){
            month = "Jan"
        }else if(month==="02"){
            month = "FEB"
        }else if(month==="03"){
            month = "MAR"
        }else if(month==="04"){
            month = "APR"
        }else if(month==="05"){
            month = "MAY"
        }else if(month==="06"){
            month = "JUN"
        }else if(month==="07"){
            month = "JUL"
        }else if(month==="08"){
            month = "AUG"
        }else if(month==="09"){
            month = "SEP"
        }else if(month==="10"){
            month = "OCT"
        }else if(month==="11"){
            month = "NOV"
        }else if(month==="12"){
            month = "DEC"
        }

        var toDate = day+"-"+month+"-"+year
        
        

        return toDate
    }
    async function startUpload(){
        const auth=getAuth() 
        signInWithEmailAndPassword(auth, "amansiewestda@gmail.com", "misunit2021")
                .then((userCredential) => {
                    // Signed in 
                  
                    
                    
                    // ...
                    const dbRef = ref(getDatabase());
                    
                    get(child(dbRef, `Identifiers/News_Id/`)).then((snapshot) => {
                      
                        var num = String(parseInt(snapshot.val()) + 1)
                        var num1 = String(parseInt(snapshot.val()) + 2)
                        var num2 = String(parseInt(snapshot.val()) + 3)
                        console.log(num+"mmmm");
                        const storage = getStorage();
                        const storageRef = refSor(storage, 'News_Pic/Image'+ num);
                        const storageRef1 = refSor(storage, 'News_Pic/Image'+ num1);
                        const storageRef2 = refSor(storage, 'News_Pic/Image'+ num2);
                        var pathUrl = ""
                        var pathUrl1 = ""
                        var pathUrl2 = ""

                        // 'file' comes from the Blob or File API
                       
                        uploadBytes(storageRef, Pic_Url).then((snapshot) => {
                            getDownloadURL(storageRef).then((url)=>{
                                pathUrl = url
                                uploadBytes(storageRef1, Pic_Url1).then((snapshot) => {
                                    getDownloadURL(storageRef1).then((url)=>{
                                        pathUrl1 = url
                                        uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                            getDownloadURL(storageRef2).then((url)=>{
                                                pathUrl2 = url
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                
                                            }).catch((error)=>{
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                            })
                
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                            })
        
                                    }).catch((error)=>{
                                        uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                            getDownloadURL(storageRef2).then((url)=>{
                                                pathUrl2 = url
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                
                                            }).catch((error)=>{
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                            })
                
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                            })
                                    })
        
                                }).catch((error)=>{
                                    uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                        getDownloadURL(storageRef2).then((url)=>{
                                            pathUrl2 = url
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
            
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                        })
            
                                    }).catch((error)=>{
                                        sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                        })
                                    })

                            }).catch((error)=>{
                                uploadBytes(storageRef1, Pic_Url1).then((snapshot) => {
                                    getDownloadURL(storageRef1).then((url)=>{
                                        pathUrl1 = url
                                        uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                            getDownloadURL(storageRef2).then((url)=>{
                                                pathUrl2 = url
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                
                                            }).catch((error)=>{
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                            })
                
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                            })
        
                                    }).catch((error)=>{
                                        uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                            getDownloadURL(storageRef2).then((url)=>{
                                                pathUrl2 = url
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                
                                            }).catch((error)=>{
                                                sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                            })
                
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                            })
                                    })
        
                                }).catch((error)=>{
                                    uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                        getDownloadURL(storageRef2).then((url)=>{
                                            pathUrl2 = url
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
            
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                        })
            
                                    }).catch((error)=>{
                                        sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                        })
                                    })
                            })

                        }).catch((error)=>{
                            uploadBytes(storageRef1, Pic_Url1).then((snapshot) => {
                                getDownloadURL(storageRef1).then((url)=>{
                                    pathUrl1 = url
                                    uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                        getDownloadURL(storageRef2).then((url)=>{
                                            pathUrl2 = url
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
            
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                        })
            
                                    }).catch((error)=>{
                                        sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                        })
    
                                }).catch((error)=>{
                                    uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                        getDownloadURL(storageRef2).then((url)=>{
                                            pathUrl2 = url
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
            
                                        }).catch((error)=>{
                                            sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                        })
            
                                    }).catch((error)=>{
                                        sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                        })
                                })
    
                            }).catch((error)=>{
                                uploadBytes(storageRef2, Pic_Url2).then((snapshot) => {
                                    getDownloadURL(storageRef2).then((url)=>{
                                        pathUrl2 = url
                                        sendData(pathUrl, pathUrl1, pathUrl2, num2)
        
                                    }).catch((error)=>{
                                        sendData(pathUrl, pathUrl1, pathUrl2, num2)
                                    })
        
                                }).catch((error)=>{
                                    sendData(pathUrl, pathUrl1, pathUrl2, num2) 
                                    })
                                })
                        });


                    }).catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                }); 
    }
    function sendData(u, u1, u2, num3){
        const db = getDatabase()
                               set(ref(db, 'News/' + num3), {
                                Text: textx,
                                Image: u,
                                Image1: u1,
                                Image2: u2,
                                Id : num3,
                                Topic : topic, Author:author, DateCreated:dob===''? currentDate():dob
                              });
                              set(ref(db, `Identifiers/`),{
                                News_Id : num3
                              }).then(()=>{
                                  setPic_Url(null)
                                  setText("")
                                  setTopic("")
                                  alert("Successfully Uploaded News")
                              })
    }
    return (
        <div>
             <div style={{backgroundColor:"#fff"}}>
         {props.hide==="true"?<div></div>:
            <div><Banner/>
            <Navbar/> </div>
            }
            <div style={{display:"flex", flexDirection:'column', justifyContent:"center", alignItems:"center", placeItems:"center", marginTop:"40px", margin:"20px"}}>

            <div  style={{display:'flex', flexDirection:'row', placeItems:'center', justifyContent:'center', alignItems:'center'}}>
					<img src={imagez!==null?imagez:nobody}  style={{height:"25%", width:"25%", placeItems:'center', justifyContent:'center', alignItems:'center'}}/>
					<input type="file" onChange={(e)=> changeHandler(e,"1")}/>
                    <img src={imagez1!==null?imagez1:nobody}  style={{height:"25%", width:"25%", placeItems:'center', justifyContent:'center', alignItems:'center'}}/>
					<input type="file" onChange={(e)=> changeHandler(e,"2")}/>
                    <img src={imagez2!==null?imagez2:nobody}  style={{height:"25%", width:"25%", placeItems:'center', justifyContent:'center', alignItems:'center'}}/>
					<input type="file" onChange={(e)=> changeHandler(e,"3")}/>

				</div>

                <FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="fname">Topic</InputLabel>
						<Input id="topic" name="topic" autoComplete="off" 
                        value={topic}
                        onChange={(e)=>{setTopic(e.target.value)}}
                          type="text" />
				</FormControl>
                <FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="fname">Author</InputLabel>
						<Input id="topic" name="topic" autoComplete="off" 
                        value={author}
                        onChange={(e)=>{setAuthor(e.target.value)}}
                          type="text" />
				</FormControl>
            
                    <TextField
                    value={textx}
                    onChange={(e)=>{setText(e.target.value)}}
                id="filled-multiline-flexible"
                label="MESSAGE"
                multiline
                rows={10}
                style={{width:"100%", height:"100%", marginTop:'20px', marginBottom:'20px'}}
                variant="filled"
                />
            <div style={{width:"1000", marginTop:20}} fullWidth>
				   <DatePicker showYearDropdown showMonthDropdown
                                    selected={dobLab} onChange={date => {setDob(convertDateForPicker(date))
                                            setDoLab(date)
                                                                 }} required fullWidth 
                                                                placeholderText={"Select Date"} 
                                                                
                 />
                 </div>

                <Button
                style={{marginTop:"20px", marginBottom:"40px"}}
                
                variant="contained"
                color="primary"
                onClick={()=>{
                   checkUpload()
               
                }}
                //to="/login"
                >
                Submit
              </Button>


        </div> 
        {props.hide==="true"?<div></div>:
        
        <Footer/>
}
        </div> 
        </div>
    )
}