import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import ProjectModel from "../Pages/ProjectModel"
import pic_1 from "../Images/COMPLETED PROJECTS/3-UNIT KG BLOCK AT MANSO ATWERE.jpg"
import pic_2 from "../Images/ATWERE.jpg"



export default function Mission(){
    const gh =[pic_1,pic_2,"No Image", "No Image", "No Image" ]
    const gh1 = ["../Images/COMPLETED PROJECTS/3-UNIT KG BLOCK AT MANSO ATWERE.jpg","No Image","No Image", "No Image", "No Image" ]
    const gh2 = ["../Images/COMPLETED PROJECTS/3-UNIT KG BLOCK AT MANSO ATWERE.jpg","No Image","No Image", "No Image", "No Image" ]
    const gh3 = ["../Images/COMPLETED PROJECTS/3-UNIT KG BLOCK AT MANSO ATWERE.jpg","No Image","No Image", "No Image", "No Image" ]
    return(
        <>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>COMPLETED PROJECT</h1>
        <ProjectModel
        imagez = {gh}
        project_name = "3-UNIT KG BLOCK AT MANSO ATWERE"
        project_text = "kfdlgkdfgjfdjkgljkfdjgfdg gnfdkgfdngfdlgndfg dfjgkdfjgkdfkjgsfndgfd gfdgnfdglsd"/>
        <h2 align="center" style={{backgroundColor:'black', height:'5px', color:"#008069", marginBottom:"20px", marginTop:"20px"}}></h2>
        <ProjectModel
        imagez = {gh1}
        project_name = "Connstruction of "
        project_text = "kfdlgkdfgjfdjkgljkfdjgfdg gnfdkgfdngfdlgndfg dfjgkdfjgkdfkjgsfndgfd gfdgnfdglsd"/>
        <h2 align="center" style={{backgroundColor:'black', height:'5px', color:"#008069", marginBottom:"20px", marginTop:"20px"}}></h2>
        </div>
        <Footer/>
        </>
    )
}



      