
import React from "react"

const ServicesMain=(props)=>{
    return(
        <div>
            <h1 align="center" style={{color:"#008069"}}>
              {props.heading}  
            </h1>
            <div style={{ display:"flex",  marginTop:"30px",  marginBottom:"30px", justifyContent:'center',  alignSelf:'center'}} >
                <img width="800px" Height="400px" alt="" src={props.src}/> 

            </div>
            <p>{props.text}</p>
        </div>
    )
}
export default ServicesMain

