import {initializeApp} from 'firebase/app';




const firebaseConfig = {
    apiKey: "AIzaSyBgJNcd1f4B4mckfa1w0EphfYl3cbcQwNU",
    authDomain: "amansie-westda.firebaseapp.com",
    databaseURL: "https://amansie-westda-default-rtdb.firebaseio.com",
    projectId: "amansie-westda",
    storageBucket: "amansie-westda.appspot.com",
    messagingSenderId: "373072661898",
    appId: "1:373072661898:web:eac1fac9b90a417ce0cc73",
    measurementId: "G-D5CW1ZGW3F"
  };
  // Initialize Firebase
  
  //const fire = app.initializeApp(firebaseConfig);
  //firebase.analytics();


  export const app = initializeApp(firebaseConfig);