import React, { useState } from 'react'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import nobody from "../Images/empty.png"
import Navbar from "../Resources/Navbar"
import Footer from '../Resources/Footer'
import { Button, FormControl, Input, InputLabel, TextField } from '@material-ui/core'
import Banner from '../Resources/Banner'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { get, getDatabase, ref, child, set } from 'firebase/database'
import {getDownloadURL, getStorage, ref as refSor, uploadBytes } from 'firebase/storage'




export default function UploadDoc(props) {

    const [openPopUp, setPopUp]=useState(false)
    const [topic, setTopic] = useState("")
    const [textx, setText] = useState("")
    

    
	const [progress, setProgress] = useState("")
	const [Pic_Url, setPic_Url] = useState(null)
	const [imagez, setImage] = useState(null)



	const changeHandler=(e)=>{
		let selected = e.target.files[0];
		const types = ['image/png', 'image/jpeg']
    
		if(selected){
			setPic_Url(selected)
			setImage(URL.createObjectURL(selected))
		   
		}else{
			setPic_Url(null)

		   // setErr("Please Select an image file(png or jpeg format)")
		}
	}
     

    function checkUpload(){
        if(Pic_Url===null){
            alert("Select a file")
        }else{
            startUpload()
        }
    }

    async function startUpload(){
        const auth=getAuth() 
        signInWithEmailAndPassword(auth, "amansiewestda@gmail.com", "misunit2021")
                .then((userCredential) => {
                    // Signed in 
                  
                    
                    
                    // ...
                    const dbRef = ref(getDatabase());
                    
                    get(child(dbRef, `Identifiers/News_Id/`)).then((snapshot) => {
                      
                        var num = String(parseInt(snapshot.val()) + 1)
                        console.log(num+"mmmm");
                        const storage = getStorage();
                        const storageRef = refSor(storage, 'Document_Pic/Image'+ num);

                        // 'file' comes from the Blob or File API
                        uploadBytes(storageRef, Pic_Url).then((snapshot) => {
                            getDownloadURL(storageRef).then((url)=>{
                               //write to db
                                const db = getDatabase()
                               set(ref(db, 'Documents/' + num), {
                                File_Name: textx,
                                File_Url: url,
                                Id : num,
                                File_Type : "pdf"
                              });

                              set(ref(db, `Identifiers/`),{
                                News_Id : num
                              }).then(()=>{
                                  setPic_Url(null)
                                  setText("")
                                  setTopic("")
                                  alert("Successfully Uploaded Documents")
                              })
                            }).catch((error)=>{
                                alert(error)
                            })
                        
                        });


                    }).catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                }); 
    }
    return (
        <div>
             <div style={{backgroundColor:"#fff"}}>
         {props.hide==="true"?<div></div>:
            <div><Banner/>
            <Navbar/> </div>
            }
            <div style={{display:"flex", flexDirection:'column', justifyContent:"center", alignItems:"center", placeItems:"center", marginTop:"40px", margin:"20px"}}>

            <div  style={{display:'flex', flexDirection:'column', placeItems:'center', justifyContent:'center', alignItems:'center'}}>
					
					<input type="file" onChange={changeHandler}/>

				</div>

                <FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="fname">Topic</InputLabel>
						<Input id="topic" name="topic" autoComplete="off" 
                        value={textx}
                        onChange={(e)=>{setText(e.target.value)}}
                          type="text" />
				</FormControl>
            
                    
                <Button
                style={{marginTop:"20px", marginBottom:"40px"}}
                
                variant="contained"
                color="primary"
                onClick={()=>{
                    checkUpload()
               
                }}
                //to="/login"
                >
                Submit
              </Button>


        </div> 
        {props.hide==="true"?<div></div>:
        
        <Footer/>
}
        </div> 
        </div>
    )
}