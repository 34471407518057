import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
// import PDFReader from '../PDFReader'
// import "/App.css"

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, child, get } from "firebase/database";
import { Button, Card, CardContent } from "@material-ui/core";
import noPic from '../Images/empty.png'
import firbase from '../Co/firease'


var uniVal=[]

export default function Documents(){


    const [mRecs, setRecs] = useState(<div></div>)
  var recs =<div></div>  
  useEffect(() => {
    setRecs(<div>Searching</div>)
     getDocs()
    }, [])

    const [tImage, setTimage] = useState("")
    
    async function getDocs(){
        const auth=getAuth() 
        signInWithEmailAndPassword(auth, "amansiewestda@gmail.com", "misunit2021")
                .then((userCredential) => {
                    // Signed in 
                  
                    
                    
                    // ...
                    const dbRef = ref(getDatabase());
                    var trh=[]
                    get(child(dbRef, `Documents/`)).then((snapshot) => {
                      if (snapshot.exists()) {
                        trh=[]
            var res={}
            snapshot.forEach((child) => {
                var name=""
                console.log(JSON.stringify(snapshot)+"mmmmmmm")
            res = {...res, File_Url : child.val().File_Url,
                File_Name : child.val().File_Name,
                Id : child.val().Id, 
                File_Type : child.val().File_Type
              
              }
              if(res!==null){
                //console.log("bb", JSON.stringify(res))
                trh.push(res)
                }
            })
          //  trh.sort((a,b) => a.Id.localeCompare(b.Id));
            //setdata(trh)
          
            uniVal = trh
           setNews("")
          
                      } else {
                        console.log("No data available");
                      }
                    }).catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                });         
}
let navigate = useNavigate()
function navTo(val){
  navigate(val)
}
function setNews(filter){
    recs = uniVal.map((val, key)=>{
     var num = key+1
       return(
           <div onClick={()=>{
             
           }}  style={{width:'90%', display:'flex', margin:'20px', justifyContent:'center', alignItems:'center'}}>
           
           <div style={{display:'flex', width:'100%',  justifyContent:'center', alignItems:'center'}} >
    

                <div style={{display:'flex', flexDirection:'row', marginBottom:10, width:'100%', justifyContent:'center', alignItems:'center'}}>
                    
                    
                    <div style={{ display:'flex', flexDirection:'row'}}>
                    {num}. 
                    <Button
                    style={{backgroundColor:'green', marginLeft:'10px'}}
                     variant="contained"
                     color="primary"
                     onClick={()=>{
                        window.open(val.File_Url)
                    
                     }}>View File</Button>

                   <label style={{ fontSize:24, fontWeight:'bold', marginLeft:'10px'}} > {val.File_Name}</label>
                    </div>
                    
                    <div style={{flex:1}}></div>
                    
            
            </div>
           

            

           </div>

           
           
           </div>
        )
    })
    

    setRecs(recs)
}
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>DOCUMENTS</h1>
        </div>
        <div className="App">
        {mRecs}
    </div>
        <Footer/>
        </>

    )
}