import React from "react"


export default function NewsHome(props){
    return(
        <>
        <div className="container" style={{background:"white"}}>
      
        <h1 style={{textAlign:"center", color:"#008069"}}>NEWS</h1>
       
        </div>
        </>
    )
}



