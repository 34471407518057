import React from "react"
import { Link, useNavigate } from 'react-router-dom'
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import "../Resources/Department.css"

const STYLES = ["btn--primary", 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

export default function Departments (props){
let navigate=useNavigate()
function NavTo(Val){
navigate(Val)
}  
    return(
        <>
        <div style={{backgroundColor:"#e4f3e1", marginBottom:"1px", height:"1200px", width: "100%"}}>
        <Banner/>
        <Navbar/>
                  <h2 style={{textAlign:"center", color:"#008069", textDecorationLine:"underline"}}>ALL DEPARTMENTS </h2>
            <div className="main__cards">
                <div onClick={()=>{NavTo("/agric")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>AGRIC DEPARTMENT</button></div>
                <div onClick={()=>{NavTo("/central")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>CENTRAL ADMINISTRATION</button></div>
                <div onClick={()=>{NavTo("/education")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>EDUCATION DIRECTORATE</button></div>
                <div onClick={()=>{NavTo("/finance")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>FINANCE</button></div>
                <div onClick={()=>{NavTo("/health" )}}className="card"><button className="btn" style={{fontWeight:"bold"}}>HEALTH DIRECTORATE</button></div>
                <div onClick={()=>{NavTo("/hr")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>HUMAN RESOURCE DEPARTMMENT</button></div>
                <div onClick={()=>{NavTo("/nadmo")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>NADMO</button></div>
                <div onClick={()=>{NavTo("/physical ")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>PHYSICAL PLANNING DEPARTMENT</button></div>
                <div onClick={()=>{NavTo("/statistics" )}} className="card"><button className="btn" style={{fontWeight:"bold"}}>STATISTICS DEPARTMENT</button></div>
                <div onClick={()=>{NavTo("/social")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>SOCIAL WELFARE & COMM. DEV’T</button></div>
                <div onClick={()=>{NavTo("/works")}} className="card"><button className="btn" style={{fontWeight:"bold"}}>WORKS DEPARTMENT </button></div>
            </div>
             
         <Footer/>
         </div>
        </>  
    )
    }
    
    
    