import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/NADMO.jpg"

export default function Nadmo(){
    return(
        <>
        <Banner/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>NATIONAL DISASTER MANAGEMENT ORGANIZATION</h1>
        
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px",display:"block", justifyContent:"center", alignItems:"center", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR. SAMUEL ASARE BREW</h3>
            <p style={{textAlign:"center"}}><b>DISTRICT DIRECTOR</b></p>
            </div>
            </div>
        <Footer/>
        </>
    )
}